import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CreateTestCycle = () => {
  return (
    <LayoutComponent>
      <h1>Create a Test Cycle</h1>

      <p>
        This guide explains how to create a test cycle from the Cycles view and
        provides key details about the process.
      </p>

      <ol>
        <li>
          On the Test Cycles main page, click <strong>+New Test Cycle</strong>{" "}
          to start creating a new test cycle.
        </li>
        <li>
          The <strong>Create Test Cycle</strong> modal will appear. Complete the
          required details for your test cycle.
        </li>
        <li>
          Once you've filled in the details, click <strong>Create</strong>. You
          will be redirected to the <strong>Test Cases</strong> tab of the newly
          created test cycle page, where you can proceed to add and configure
          test cases for the test cycle.
        </li>
      </ol>
      <h5>Additional Information:</h5>
      <p>
        Once the test cycle is created, you can further configure it using the
        tabs available:
      </p>

      <ul>
        <li>
          On the <strong>Details</strong> tab, review and edit the test cycle's
          details.
        </li>
        <li>
          On the <strong>Test Cases</strong> tab, add test cases from the
          existing list to the cycle. You can also assign environments and
          testers to each test case.
        </li>
        <li>
          The <strong>Linked Issue</strong> tab displays any issues logged
          during the test cycle execution.
        </li>
        <li>
          Use the <strong>Attachments</strong> tab to upload relevant files for
          reference.
        </li>
        <li>
          The <strong>Comments</strong> tab allows you to add notes or comments
          related to the test cycle.
        </li>
        <li>
          Click the <strong>Play Icon</strong> on Test Cases tab to navigate to
          the Test player and start executing the test cases.
        </li>
      </ul>
    </LayoutComponent>
  )
}

export default CreateTestCycle
